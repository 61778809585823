import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Api } from "../../ApiData";
import { toast } from "react-toastify";
import { Table, Modal, Button, Spinner } from "react-bootstrap";
import UserImage from "../../assets/images/user.png";

const EmployeeDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [empDetails, setEmpDetails] = useState();
  const [tasks, setTaskList] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [filteredTasks, setFilteredTasks] = useState([]);

  const getTimeFormat = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);

    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };

    const formattedDate = dateTime.toLocaleDateString('en-US', options);

    console.log(formattedDate);
    return formattedDate;

  }

  const getEmployeeDetailsById = async () => {
    let id = params?.id;
    let apiRes = await Api.getEmployeeDetailsById(id);
    if (apiRes.status >= 200 && apiRes.status <= 399) {
      setEmpDetails(apiRes.body);
    }
    if (apiRes.status >= 400 && apiRes.status <= 500) {
      toast.error(apiRes.message);
      if (apiRes.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const getMyTaskListById = async () => {
    let id = params?.id;
    let apiRes = await Api.getMyTaskListById(id);
    if (apiRes.status >= 200 && apiRes.status <= 399) {
      setTaskList(apiRes.body);
    }
    if (apiRes.status >= 400 && apiRes.status <= 500) {
      toast.error(apiRes.message);
      if (apiRes.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const getProjects = async () => {
    let apiRes = await Api.getProjects();
    if (apiRes.status >= 200 && apiRes.status <= 399) {
      setProjects(apiRes.body);
    }
    if (apiRes.status >= 400 && apiRes.status <= 500) {
      toast.error(apiRes.message);
      if (apiRes.statusCode === 401) {
        navigate("/");
      }
    }
  };

  const handleProjectNameClick = (projectId) => {
    navigate(`/project-detail/${projectId}`); 
  };


  useEffect(() => {
    getEmployeeDetailsById();
    getMyTaskListById();
    getProjects();
  }, []);

 useEffect(() => {
  if (selectedProject === "") {
    setFilteredTasks(tasks); 
  } else {
    const filteredTasks = tasks.filter((task) => task.project_id === selectedProject);
    setFilteredTasks(filteredTasks);
  }
}, [selectedProject, tasks]);

  return (
    <Fragment>
      <div className="page-heading employee-profile right-nav-section" id="employee-pro">
      <div className="employee-details">
          <img
            className="profile-pic"
            src={
              empDetails?.full_image === null
                ? UserImage
                : empDetails?.full_image
            }
            alt="Profile Picture"
          />
          <div className="employee-info">
            <h2>
              {empDetails?.first_name} {empDetails?.last_name}
            </h2>
            <p>{empDetails?.email}</p>
            <strong>{empDetails?.dest_name}</strong>, specializing in{" "}
            <strong>{empDetails?.dept_name}</strong> development.
          </div>
        </div>
        
        <h3>Task List</h3>
       <select
                    className="form-control"
                    value={selectedProject}
                    onChange={(e) => setSelectedProject(Number(e.target.value))} 
                  >
                    <option value="">All Projects</option>
                    {projects &&
                      projects.map((project) => (
                        <option key={project.project_id} value={project.project_id}>
                          {project.project_name}
                        </option>
                      ))}
                  </select>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Project Name</th>
            <th>Task Title</th>
            <th>Due Dates</th>
            <th>Assigned By</th>
            <th>Notes</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredTasks &&
            filteredTasks.map((task, index) => (
              <tr key={task.task_id}>
                <td>{++index}</td>
                <td onClick={() => handleProjectNameClick(task.project_id)} style={{ cursor: 'pointer', color: 'black' }}>
                {task.project_name}
              </td>
                <td>{task.task_title}</td>
                <td>{getTimeFormat(task.start_date)} - {getTimeFormat(task.due_date)}</td>
                <td>{task.assign_by_first_name} {task.assigned_by_last_name}</td>
                <td>{task.note}</td>
                <td><span className="badge">{task.task_status_title}</span></td>
              </tr>
            ))}
          {filteredTasks.length === 0 ? (
            <tr>
              <td colSpan={7}>No tasks available</td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default EmployeeDetails;