import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Api } from "../../ApiData"; 
import { toast } from "react-toastify";

const ProjectDetails = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState({});
  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const apiRes = await Api.getProjectDetailById(projectId);
        if (apiRes?.status >= 200 && apiRes?.status <= 399) {
          setProject(apiRes.body);
        } else {
          toast.error("Failed to fetch project details.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching project details.");
      } finally {
        setLoading(false);
      }
    };

    fetchProjectDetails();
  }, [projectId]);

  useEffect(() => {
    const fetchStateBranch = async () => {
      try {
        const resp = await Api.getUserList();
        const userListMap = resp.body.reduce((acc, user) => {
          acc[user.id] = user.name;
          return acc;
        }, {}); 
        setUserList(userListMap);
      } catch (error) {
        toast.error("An error occurred while fetching user list.");
      }
    };

    fetchStateBranch();
  }, []);



  if (loading) {
    return <div>Loading...</div>;
  }

  if (!project) {
    return <div>Project not found.</div>;
  }
  const getUserName = (id) => {
    return userList[id] || 'Not assigned';
  };



  return (
    <div className="project-details-container">
      <h2>Project Details</h2>
      <div className="project-info">
        <div className="project-header">
          <p>
            <strong>Project Name:</strong> {project.project_name}
          </p>
          <p>
            <strong>Duration:</strong> {new Date(project.start_date).toLocaleDateString()} - {new Date(project.end_date).toLocaleDateString()}
          </p>
        </div>
        <div className="project-leaders">
        <p>
            <strong>Client Name:</strong> {project.client_name}
          </p>
          <p>
            <strong>Project Manager:</strong>  <Link to={`/employees-list/view-employee/${project.project_manager}`} className="custom-link">
                                {getUserName(project.project_manager)}
                                </Link>
          </p>
          <p>
            <strong>Team Leader:</strong> <Link to={`/employees-list/view-employee/${project.team_leader}`} className="custom-link">
                                {getUserName(project.team_leader)}
                                </Link>
          </p>
        </div>
        <div className="team-members">
                    <h3>Team Members:</h3>
                    <table>
                        <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Member Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        {project.team_members.map((member, index) => (
                            <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                <Link to={`/employees-list/view-employee/${member}`} className="custom-link">
                                {getUserName(member)}
                                </Link>
                            </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>
      </div>
    </div>
  );
};

export default ProjectDetails;